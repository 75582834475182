<template>
    <div class="app-container">
    <el-row :gutter="4" type="flex" justify="center">
      <el-col :span="20">
        <el-card class="box-card">
          <div slot="header" class="clearfix" justify="center" align="center">
            <strong><h3>Amo Chapultepec</h3></strong>
          </div>
          <div class="text item">
            <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form @keypress.enter.native.prevent="handlerCreate" label-position="top" class="size-form-item" label-width="120px" :model="form">
                <el-row justify="space-around" align="middle">
                  <el-col >
                    <el-form-item label="Título">
                      <ValidationProvider name="Título - Amo Chapultepec" rules="required" v-slot="{ errors }">
                        <el-input size="mini" v-model="form.title"></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="Descripción">
                      <ValidationProvider name="Descripción - Amo Chapultepec" rules="required" v-slot="{ errors }">
                        <el-input type="textarea" :rows="4" v-model="form.description"></el-input>
                        <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="Imagen">
                      <ImageUpload
                        :postImageTo="postImageTo"
                        :getImagesFrom="getGallery"
                        :setImage="form.image"
                        @getImage="getSelectedImageAmo"
                        ></ImageUpload>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="center">
                  <el-col :xs="8" :md="6" align="middle">
                    <el-form-item>
                      <el-button
                        @click.prevent="handlerCreate"
                        type="primary"
                        icon="el-icon-upload2"
                        :disabled="invalid">
                        Actualizar
                      </el-button>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="8" :md="6" align="middle">
                    <el-form-item>
                      <el-button
                        @click.prevent="handlerCancel"
                        type="danger">
                        Cancelar
                      </el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </ValidationObserver>
          </div>
        </el-card>
      </el-col>
    </el-row>
    </div>
  </template>

<script>
import { uploadImageFile } from '@/api/image.js'
import { search } from '@/api/gallery.js'
import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import { createChapultepec, fetchInfoChapultepec } from '@/api/app.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'createChapultepec',
  components: {
    ImageUpload
  },
  data () {
    return {
      host: urlServer,
      form: {
        title: '',
        description: '',
        image: null
      },
      loadingImage: false,
      imageSelected: false
    }
  },
  mounted () {
    this.fetchInfo()
  },
  methods: {
    async fetchInfo () {
      await fetchInfoChapultepec().then(response => {
        var data = response.data
        if (data.length !== 0) {
          this.form = data
        }
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async postImageTo (param) {
      return await uploadImageFile(param)
    },
    async getGallery (param) {
      return await search(param)
    },
    getSelectedImageAmo (imageUploaded) {
      this.form.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.image)
    },
    handlerCancel () {
      this.$router.back()
    },
    async handlerCreate (info) {
      console.log(this.form)
      if (this.form.image !== '' && this.form.image !== null) {
        try {
          const response = await createChapultepec(this.form)
          if (response.success) {
            console.log(response)
            this.$message({
              showClose: true,
              message: '¡Se creó correctamente!',
              type: 'success'
            })
            // this.$router.push('/projects')
          } else {
            console.log(response)
          }
        } catch (err) {
          err.response.data.messages(message => {
            this.$message.error(`${message}`)
          })
        }
      } else {
        this.$message.error('Asegurate que no falte ningun archivo o dato')
      }
    }
  }
}
</script>

  <style scoped>
  .label-form-validate-wrong {
    color: #F56C6C;
  }

  .size-form-item > .el-form-item {
    margin: 0;
  }
  .size-form-item > .el-form--label-top .el-form-item__label {
    padding: 0;
    margin-bottom: 0;
  }
  .my-autocomplete {
    width: 500px;
  }
  </style>
